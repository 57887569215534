import React from 'react'
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { theme } from 'styled-tools';
import PressPosts from './PressPosts';
import { usePressPostsContext } from 'context/PressPostsContext';

const INIT_NEWS_TO_SHOW = 3;
const INIT_PRESS_RELEASES_TO_SHOW = 3;
const INIT_AWARDS_TO_SHOW = 4;

export const SectionHeader = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 600;
  margin: 2rem 0 0 0;
  text-transform: uppercase;
`;

export const SmallHeader = styled(Typography)`
  font-size: 1.2rem;
  font-weight: 600;
  margin: 2rem 0 1.5rem 0;
  text-transform: uppercase;
`;

export const SmallText = styled(Typography)`
  font-size: 0.875rem;
  font-weight: 400;
  font-family: ${theme('fonts.robotoCondensed')};
`;

function PressPostsList() {
  const { pressPosts, activeTab } = usePressPostsContext();

  return (
    <>
      <SectionHeader variant='h2'>{activeTab}</SectionHeader>
      <SmallText>
        {(activeTab === 'All' || activeTab === 'tastylive') 
          && '*tastylive will be shown as tastytrade in results prior to December 12, 2022'}
        {activeTab === 'All' && <br />}
        {(activeTab === 'All' || activeTab === 'tastytrade') 
          && '*tastytrade will be shown as tastyworks in results prior to February 20, 2023'}
      </SmallText>

      {!!pressPosts?.news?.length && (
        <>
          <SmallHeader variant='h2'>News</SmallHeader>
          <PressPosts origin={pressPosts.news} initialNumberToShow={INIT_NEWS_TO_SHOW}/>
        </>
      )}

      {!!pressPosts?.pressReleases?.length && (
        <>
          <SmallHeader variant='h2'>Press Releases</SmallHeader>
          <PressPosts origin={pressPosts.pressReleases} initialNumberToShow={INIT_PRESS_RELEASES_TO_SHOW}/>
        </>
      )}

      {!!pressPosts?.awards?.length && (
        <>
          <SmallHeader variant='h2'>Awards</SmallHeader>
          <PressPosts origin={pressPosts.awards} initialNumberToShow={INIT_AWARDS_TO_SHOW}/>
        </>
      )}
    </>
  )
}

export default React.memo(PressPostsList);