import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import getPressPostsQuery from '../../graphql/queries/getPressPosts.graphql';
import { useQuery } from '@apollo/client';

PressPostsContextProvider.propTypes = {
  children: PropTypes.node
}

const defaultContext = {
  pressPosts: {news: [], awards: [], pressReleases: []},
  activeTab: 'All',
  handleChangeYear: (selectedYear) => {},
  handleChangeTab: (selectedTab) => {},
}

const PressPostsContext = createContext(defaultContext);

export function PressPostsContextProvider({ children }) {
  const [pressPosts, setPressPosts] = useState();
  const [activeTab, setActiveTab] = useState('All');
  const { loading, error, data, refetch } = useQuery(getPressPostsQuery, {
    variables: {
      filters: {
        activeTab: 'All',
        year: 'All',
      }
    },
    fetchPolicy: 'network-only', 
  });

  const handleChangeYear = (selectedYear) => {
    refetch({
      filters: {
        activeTab,
        year: selectedYear,
    }});
  }

  const handleChangeTab = (selectedTab) => {
    setActiveTab(selectedTab);

    refetch({
      filters: {
        activeTab: selectedTab,
        year: 'All',
    }});
  }

  useEffect(() => {
    setPressPosts(data?.pressPosts);
  }, [data?.pressPosts]);

  return (
    <PressPostsContext.Provider
      value={{
        pressPosts,
        activeTab,
        handleChangeTab,
        handleChangeYear,
        loading,
        error,
      }}
    >
      {children}
    </PressPostsContext.Provider>
  )
}

export const usePressPostsContext = () => useContext(PressPostsContext);
