import React, { useState } from 'react';
import useTheme from '@material-ui/core/styles/useTheme';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import pressPostsShape from 'shapes/components/pressPostsShape';
import Icon from 'components/Icon';

const SHOW_MORE_AMOUNT = 1;

const PressPostsContainer = styled.div`
  display: flex;
  grid-row-gap: 1.25rem;
  flex-direction: column;
  box-sizing: border-box;
`;

const PressPostContainer = styled.div`
  grid-column-gap: 1.5rem;
  align-items: center;
  display: flex;
  box-sizing: border-box;
`;

const PressPostLine = styled.hr`
  border-bottom: none;
  border-top: 0.0625rem solid ${theme('colors.borderGray')};
  margin: 0;
`;

const PressPostDate = styled.div`
  text-transform: uppercase;
`;

const PressPostTitle = styled.a`
  font-size: 1.2rem;
  font-weight: normal;
  margin: 1rem 0 1rem 0;
  text-decoration: none;
  color: ${theme('colors.black')};

  &:hover {
    color: ${theme('colors.ttRed')};
    cursor: pointer;
  }
`;

const PressPostImage = styled.img`
  height: 6.313rem;
  width: 11rem;
  display: inline-block;
`;

const ShowMore = styled.div`
  cursor: pointer;
  width: fit-content;
  font-weight: bold;
  margin-top: 0;
  display: flex;
  align-items: center;
  gap: 0.3rem;

  &:hover {
    color: ${theme('colors.ttRed')};
    text-decoration: none;
  }
`;

const PressPostContent = styled.div`
  width: 100%;
`;

PressPosts.propTypes = {
  origin: pressPostsShape,
  initialNumberToShow: PropTypes.number,
};

function PressPosts({ origin, initialNumberToShow }) {
  const [numberToShow, setNumberToShow] = useState(initialNumberToShow);
  const formatDate = (date) => date.toLocaleDateString(undefined, { month: 'long', year: 'numeric', day: 'numeric'});
  const theme = useTheme();

  return <PressPostsContainer>
    {origin?.slice(0, numberToShow)?.map((post) => {
      return (
        <PressPostContainer key={post.uid}>
          {post.thumbnail?.url && (
            <PressPostImage src={post.thumbnail.url} alt={post.metadata.thumbnailAltText}/>
          )}
          <PressPostContent>
            {post?.postDate && (
              <PressPostDate>{formatDate(new Date(post.postDate))}</PressPostDate>
            )}
            {post?.title && (
              <PressPostTitle href={post.url} target='_blank'>{post.title}</PressPostTitle>
            )}
            <PressPostLine/>
          </PressPostContent>
        </PressPostContainer>
      )
    })}
    {
      (numberToShow < origin?.length) ? (
        <ShowMore onClick={() => setNumberToShow(numberToShow + SHOW_MORE_AMOUNT)}>
          <span>Show more</span>
          <Icon icon="chevronDown" color={theme.colors.ttRed} size={0.8} />
        </ShowMore>
      ) : (origin?.length > 3) ? (
        <ShowMore onClick={() => setNumberToShow(initialNumberToShow)}>
          <span>Show less</span>
          <Icon icon="chevronUp" color={theme.colors.ttRed} size={0.8} />
        </ShowMore>
      ) : null
    }
  </PressPostsContainer>;
}

export default PressPosts;